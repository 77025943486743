import request from '@/utils/request'

export function getPage (page, noitems) {
  return request({
    url: '/surveys/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function getSurveys (id) {
  return request({
    url: '/surveys/' + id,
    method: 'get'
  })
}
export function search (data) {
  return request({
    url: '/surveys/search',
    method: 'post',
    data
  })
}
export function storeSurvey (data) {
  return request({
    url: '/surveys',
    method: 'post',
    data
  })
}

export function getSurvey (id) {
  return request({
    url: '/surveys/' + id,
    method: 'get'
  })
}
export function updateSurvey (id, data) {
  return request({
    url: '/surveys/' + id,
    method: 'put',
    data
  })
}
export function destroySurvey (_id) {
  return request({
    url: '/surveys/' + _id,
    method: 'delete'
  })
}
export function exportExcel (data) {
  return request({
    url: '/surveys/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
