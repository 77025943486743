var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-col',{attrs:{"sm":24,"md":16}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Agregar encuesta")])]),_c('div',{staticClass:"text item"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{attrs:{"label-position":"top","label-width":"100px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"Lugar de trabajo"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.workPlace),expression:"form.workPlace"}],staticClass:"width-100",attrs:{"multiple":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "workPlace", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.listItemsWorkplace),function(itemWork){return _c('option',{key:itemWork.id},[_vm._v(" "+_vm._s(itemWork)+" ")])}),0)]),_c('el-form-item',{attrs:{"label":"Título","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"titulo","rules":"required|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Descripción"}},[_c('tinyeditor',{attrs:{"api-key":"epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex","init":{
                      height: 300,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('el-form-item',{attrs:{"label":"Duración aproximada","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"duración","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.duration),callback:function ($$v) {_vm.$set(_vm.form, "duration", $$v)},expression:"form.duration"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Imagen"}},[(_vm.form.image)?_c('div',[_c('el-image',{attrs:{"src":_vm.$urlGlobalApi + _vm.form.image}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])],1):_vm._e(),_c('el-upload',{attrs:{"action":"","drag":"","name":"image","http-request":_vm.postImage,"on-success":_vm.handleAvatarSuccess,"multiple":""}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("Suelta tu foto aquí o "),_c('em',[_vm._v("haz clic para cargar")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Solo archivos jpg/png con un tamaño menor de 500kb (800 x 450)")])])],1),_c('el-card',{staticClass:"card-notification",attrs:{"shadow":"never"}},[_vm._l((_vm.form.questions),function(params,index){return _c('el-form-item',{key:params.key,staticClass:"size-tips",attrs:{"label":'Pregunta ' + index,"prop":'questions.' + index + '.answers'}},[_c('el-col',{attrs:{"span":10}},[_c('span',[_vm._v("Pregunta")]),_c('ValidationProvider',{attrs:{"name":"pregunta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"Pregunta"},model:{value:(params.question),callback:function ($$v) {_vm.$set(params, "question", $$v)},expression:"params.question"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-col',{attrs:{"span":11}},[_c('span',[_vm._v("Posibles respuestas")]),_c('ValidationProvider',{attrs:{"name":"respuesta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"Nómina,Noticias,Encuestas"},model:{value:(params.answers),callback:function ($$v) {_vm.$set(params, "answers", $$v)},expression:"params.answers"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('span',[_vm._v("Ingresar las respuestas separadas por coma "),_c('strong',[_vm._v("Ejemplo:")]),_vm._v(" Nómina,Noticias,Encuestas")])],1),_c('el-col',{attrs:{"span":3}},[_c('el-button',{staticClass:"margin-top-20",attrs:{"size":"mini"},on:{"click":function($event){$event.preventDefault();return _vm.removeInput(params)}}},[_vm._v("Eliminar")])],1)],1)}),_c('el-form-item',[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.addInput}},[_vm._v("Agregar pregunta")])],1)],2),_c('el-form-item',{attrs:{"label":"Categoria"}},[_c('ValidationProvider',{attrs:{"name":"categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-select',{staticClass:"width-100",attrs:{"size":"mini","multiple":"","filterable":"","allow-create":"","placeholder":"Comunicación"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}},_vm._l((_vm.optionsTags),function(itemT){return _c('el-option',{key:itemT.value,attrs:{"label":itemT.label,"value":itemT.value}})}),1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Orden"}},[_c('ValidationProvider',{attrs:{"name":"orden","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-input-number',{attrs:{"size":"mini","controls-position":"right","min":0},model:{value:(_vm.form.order),callback:function ($$v) {_vm.$set(_vm.form, "order", $$v)},expression:"form.order"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Fecha de publicación"}},[_c('ValidationProvider',{attrs:{"name":"fecha de publicación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-date-picker',{attrs:{"size":"mini","type":"date","format":"dd-MM-yyyy","placeholder":"Fecha"},model:{value:(_vm.form.datePublication),callback:function ($$v) {_vm.$set(_vm.form, "datePublication", $$v)},expression:"form.datePublication"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Publicar"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","size":"small","disabled":invalid,"loading":_vm.loading,"icon":"el-icon-upload2"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onStore($event)}}},[_vm._v(" Crear")])],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }